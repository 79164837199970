import AOS from "aos"
import "aos/dist/aos.css"
import { Link } from "gatsby"
import React, { useEffect } from "react"
import SocialIcons from "../SocialIcons/SocialIcons"
import "./location-block.scss"
import MapWithAMarker from "./MapWithAMarker"
const LocationBlock = ({ locationContent, lang, onClick, uri }) => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  const [map, changeMap] = React.useState("thao dien")
  return (
    <section className="block-location">
      <div
        className="container"
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-delay="10"
        data-aos-duration="2000"
      >
        <h2 className="block-location__title d-flex-row">
          <span className="stroke-text">{locationContent.strokeTitle}</span>
          <span>{locationContent.solidTitle}</span>
        </h2>
        <p className="block-location__subtitle">{locationContent.subTitle}</p>
        <div className="block-location__content d-flex-row justify-content-between">
          <div className="block-location__addresses d-flex-column">
            <div className="block-location__address-item d-flex-row align-items-end justify-content-between">
              <div className="block-location__address-item__detail">
                <p
                  onClick={onClick}
                  className="block-location__address-item__detail__note"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="#914cff"
                    className="bi bi-star-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  {locationContent.tdStarterDealText}
                </p>

                <Link
                  className="block-location__address-item__detail__name"
                  to={uri.thaodien}
                >
                  {locationContent.tdLocationTitle}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#ff7f7f"
                    className="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </Link>
                <p className="address">{locationContent.tdAddressText}</p>

                <div className="contact_details">
                  <a
                    className="phonenumber  d-flex-row align-items-center"
                    href={`tel:${locationContent.tdPhoneNumber}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="#ff7f7f"
                      className="bi bi-telephone-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                      />
                    </svg>
                    <p>{locationContent.tdPhoneNumber}</p>
                  </a>
                  <a
                    className="phonenumber email  d-flex-row align-items-center"
                    href={`mailto:${locationContent.tdEmailAddress}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 42.81 34.25"
                      width="16"
                      height="16"
                      fill="#ff7f7f"
                    >
                      <title>email-icon</title>
                      <g id="ELEMENTS">
                        <path
                          className="cls-1"
                          d="M6.35,3.89l12.29,14a3.7,3.7,0,0,0,5.53,0L36.25,4.13a1.44,1.44,0,0,1,.34-.27,4.06,4.06,0,0,0-1.67-.35h-27a4,4,0,0,0-1.6.32A.34.34,0,0,1,6.35,3.89Z"
                        />
                        <path
                          className="cls-1"
                          d="M38.45,5.47a1.29,1.29,0,0,1-.23.39L26.14,19.57a6.31,6.31,0,0,1-9.47,0L4.38,5.62l-.06-.09a4.17,4.17,0,0,0-.61,2.15V26.56a4.18,4.18,0,0,0,4.18,4.18h27a4.18,4.18,0,0,0,4.18-4.18V7.68A4.09,4.09,0,0,0,38.45,5.47Z"
                        />
                      </g>
                    </svg>
                    <p>{locationContent.tdEmailAddress}</p>
                  </a>
                </div>
                <SocialIcons
                  facebookLink="https://www.facebook.com/Hustle.thaodien/"
                  instagramLink="https://www.instagram.com/hustle.thaodien/"
                  youtubeLink="https://www.youtube.com/channel/UC3YfB8GO2a4My7nyp6FjK2A"
                  tiktokLink="https://www.tiktok.com/@hustlevietnam"
                />
              </div>
              <div className="block-location__address-item__book-link">
                <Link to={uri.thaodien}>{locationContent.tdCtaText}</Link>
              </div>
            </div>

            <hr></hr>

            <div className="block-location__address-item d-flex-row align-items-end justify-content-between">
              <div className="block-location__address-item__detail ">
                <p
                  onClick={onClick}
                  className="block-location__address-item__detail__note"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="#914cff"
                    className="bi bi-star-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  {locationContent.gvStarterDealText}
                </p>

                <Link
                  className="block-location__address-item__detail__name"
                  to={uri.govap}
                  target="_blank"
                >
                  {locationContent.gvLocationTitle}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#ff7f7f"
                    className="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </Link>

                <p className="address">{locationContent.gvAddressText}</p>
                <div className="contact_details">
                  <a
                    className="phonenumber  d-flex-row align-items-center"
                    href={`tel:${locationContent.gvPhoneNumber}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="#ff7f7f"
                      className="bi bi-telephone-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                      />
                    </svg>
                    <p>{locationContent.gvPhoneNumber}</p>
                  </a>
                  <a
                    className="phonenumber email  d-flex-row align-items-center"
                    href={`mailto:${locationContent.gvEmailAddress}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 42.81 34.25"
                      width="16"
                      height="16"
                      fill="#ff7f7f"
                    >
                      <title>email-icon</title>
                      <g id="ELEMENTS">
                        <path
                          className="cls-1"
                          d="M6.35,3.89l12.29,14a3.7,3.7,0,0,0,5.53,0L36.25,4.13a1.44,1.44,0,0,1,.34-.27,4.06,4.06,0,0,0-1.67-.35h-27a4,4,0,0,0-1.6.32A.34.34,0,0,1,6.35,3.89Z"
                        />
                        <path
                          className="cls-1"
                          d="M38.45,5.47a1.29,1.29,0,0,1-.23.39L26.14,19.57a6.31,6.31,0,0,1-9.47,0L4.38,5.62l-.06-.09a4.17,4.17,0,0,0-.61,2.15V26.56a4.18,4.18,0,0,0,4.18,4.18h27a4.18,4.18,0,0,0,4.18-4.18V7.68A4.09,4.09,0,0,0,38.45,5.47Z"
                        />
                      </g>
                    </svg>
                    <p>{locationContent.gvEmailAddress}</p>
                  </a>
                </div>

                <SocialIcons
                  facebookLink="https://www.facebook.com/hustle.govap/"
                  instagramLink="https://www.instagram.com/hustle.govap/"
                  youtubeLink="https://www.youtube.com/channel/UC3YfB8GO2a4My7nyp6FjK2A"
                  tiktokLink="https://www.tiktok.com/@hustlevietnam"
                />
              </div>
              <div className="block-location__address-item__book-link">
                <Link to={uri.govap}>{locationContent.gvCtaText}</Link>
              </div>
            </div>

            <hr className="seperate-map-content"></hr>
          </div>

          <div className="block-location__maps">
            <div className="block-location__maps__button">
              <button
                className={map === "thao dien" ? "current-map" : "change-map"}
                type="button"
                onClick={() => changeMap("thao dien")}
              >
                HUSTLE THAO DIEN
              </button>
              <button
                className={map === "go vap" ? "current-map" : "change-map"}
                type="button"
                onClick={() => changeMap("go vap")}
              >
                HUSTLE GO VAP
              </button>
            </div>
            {map === "thao dien" && (
              <MapWithAMarker
                location={{
                  lat: 10.804758504140676,
                  lng: 106.73736320428709,
                  info: "HUSTLE THAO DIEN",
                }}
              />
            )}
            {map === "go vap" && (
              <MapWithAMarker
                location={{
                  lat: 10.822299686984806,
                  lng: 106.69194533589648,
                  info: "HUSTLE GO VAP",
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
export default LocationBlock
