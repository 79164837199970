import React from "react"
import "./location-block.scss"
import icon from "../../images/location-pin-02.svg"
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoBox,
} from "@react-google-maps/api"
const options = { closeBoxURL: "", enableEventPropagation: true }
const containerStyle = {
  width: "100%",
  height: "90vh",
}
const MapWithAMarker = ({ location }) => {
  const { isLoaded } = useJsApiLoader({
    id: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCvKYpp6yD29c5T-dVuWHH79RgmQmD_nJA&v=3.exp&libraries=geometry,drawing,places",
    googleMapsApiKey: "AIzaSyBfbLKPI7eEILSthYKHicmNE_a1hAqQkYs",
  })
  const [map, setMap] = React.useState(null)
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(location)
    map.fitBounds(bounds)
    setMap(map)
  }, [])
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: location.lat, lng: location.lng }}
        defaultZoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
        defaultOptions={{
          mapTypeControl: false,
          streetViewControl: false,
        }}
      >
        <Marker
          icon={{
            url: `${icon}`,
            scaledSize: new window.google.maps.Size(30, 40),
          }}
          position={{ lat: location.lat, lng: location.lng }}
        >
          <InfoBox options={options}>
            <>
              <div
                style={{
                  backgroundColor: "#ff7f7f",
                  color: "#000",
                  borderRadius: "1em",
                  padding: "5px",
                  top: "-35px",
                  left: "20px",
                }}
              >
                {location.info}
              </div>
            </>
          </InfoBox>
        </Marker>
      </GoogleMap>
    </div>
  ) : (
    <></>
  )
}
export default React.memo(MapWithAMarker)
