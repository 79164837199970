import React, { useState } from "react"
import { graphql } from "gatsby"
import {
  Layout,
  Seo,
  Banner,
  ErrorBoundary,
  MindBodyPage,
} from "../../index.js"
import LocationBlock from "../components/LocationBlock/LocationBlock"
const Location = ({ data, pageContext }) => {
  let locationContent = data.location.blocks[1].locationBlock
  let lang = pageContext.slug
  // handle toggle form
  const [toggle, setToggle] = useState(false)
  const onClick = () => {
    const healCode = document.querySelector("healcode-widget")
    if (healCode) {
      healCode.style.display = toggle ? "none" : "block"
    }
    setToggle(!toggle)
  }

  return (
    <Layout>
      <Seo
        title={data.location.seo?.title}
        description={data.location.seo?.metaDesc}
        featuredImage={
          data.location.seo?.opengraphImage?.localFile.childImageSharp
            .gatsbyImageData
        }
      />
      <Banner onClick={onClick} hidden={toggle} />
      <LocationBlock
        locationContent={locationContent}
        lang={lang}
        onClick={onClick}
        uri={{
          govap: data.govap.uri,
          thaodien: data.thaodien.uri,
        }}
      />
      {toggle && (
        <ErrorBoundary>
          <MindBodyPage toggleForm={onClick} toggle={toggle} />
        </ErrorBoundary>
      )}
    </Layout>
  )
}
export const query = graphql`
  query ($slug: String!, $lang: String!) {
    location: wpPage(slug: { eq: $slug }, language: { slug: { eq: $lang } }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      blocks {
        ... on WpAcfTextBlock {
          textBlock {
            textBlockTitle
            textBlockTitleStroke
            textBlockSubTitle
            textCard {
              textCardContent
              textCardTitle
              textCardImage {
                sourceUrl
              }
            }
          }
        }
        ... on WpAcfLocationBlock {
          locationBlock {
            gvAddressText
            gvCtaText
            gvEmailAddress
            gvLocationTitle
            gvPhoneNumber
            gvStarterDealText
            solidTitle
            strokeTitle
            subTitle
            tdAddressText
            tdCtaText
            tdEmailAddress
            tdLocationTitle
            tdPhoneNumber
            tdStarterDealText
          }
        }
      }
    }
    govap: wpPage(title: { eq: "Go Vap" }, language: { slug: { eq: $lang } }) {
      uri
    }
    thaodien: wpPage(
      title: { eq: "Thao Dien" }
      language: { slug: { eq: $lang } }
    ) {
      uri
    }
  }
`

export default Location
